.aboutUs {
  position: relative;
  width: 90%;

  max-width: 1136px;
  margin: 0 auto;
  margin-top: 80px;
  z-index: 1;
}

.h2 {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 100%;

  text-align: center;

  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.7) 101.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.desc {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  color: #ffffff;
  max-width: 630px;
  margin: 0 auto;
  margin-top: 8px;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 560px;
  height: 498px;

  margin-top: 80px;
  border-radius: 32px;
}

.right {
  width: 460px;
  margin-top: 190px;
}

.rightDesc {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: #ffffff;
  margin-bottom: 16px;
}

@media (max-width: 1175px) {
  .bottom {
    display: block;
  }

  .left {
    display: flex;
    width: 460px;
    height: auto;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 32px;
  }

  .right {
    width: 460px;
    margin: 0 auto;
    margin-top: 32px;
  }
}

@media (max-width: 550px) {
  .left {
    width: 100%;
  }

  .right {
    width: 100%;
  }

  .rightDesc {
    font-size: 14px;
  }
}

@media (max-width: 440px) {
  .h2 {
    font-size: 32px;
  }

  .desc {
    font-size: 14px;
  }
}

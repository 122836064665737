.left {
  position: relative;
  width: 240px;
}

.title {
  font-size: 22px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  line-height: 28px;

  background: linear-gradient(91.1deg, #b3b0ae 5.25%, #ffffff 88.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.mobileStep {
  position: relative;
  display: none;
  width: 100%;
  margin-top: 44px;

  color: #656565;
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 20px;
}

.points {
  position: relative;
  margin-top: 32px;
  width: 100%;
}

.onePoint {
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 16px;
  border-radius: 10px;
  background: #2f2f2f00;
}

.onePoint .number {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 100%;

  background: #080601;

  color: #656565;
  text-align: center;

  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 24px;
  padding-top: 2px;
  padding-left: 1px;
}

.onePoint .pointTitle {
  color: #656565;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 24px;
  margin-top: 2px;
}

.selectedPoint {
  background: #2f2f2f;
}

.selectedPoint .number {
  background: #eda15c;
  color: #080601;
}

.selectedPoint .pointTitle {
  color: #eda15c;
}

@media (max-width: 920px) {
  .left {
    width: 100%;
  }

  .mobileStep {
    display: block;
  }

  .points {
    margin-top: 4px;
  }

  .onePoint {
    display: none;
    padding: 0px;
    border-radius: 0px;
    background: #2f2f2f00;
  }

  .selectedPoint {
    display: block;
    background: #2f2f2f00;
  }

  .onePoint .number {
    display: none;
  }

  .onePoint .pointTitle {
    color: #ffffff;
    font-size: 24px;
    font-family: "Bebas Neue", sans-serif;
    line-height: 36px;
    font-weight: 600;
    margin-top: 0px;
  }
}

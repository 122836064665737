@import "src/styles/scss/variables";

.roadmap {
  position: relative;
  width: 100%;

  background: #171717;
  border-radius: 32px;
  padding: 32px;
  margin-top: 24px;
}

.mainTitle {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 125%;

  background: linear-gradient(
    270.02deg,
    #ffffff 4.58%,
    rgba(255, 255, 255, 0.7) 97.24%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.desc {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #ffffff;
  margin-top: 18px;
}

.smallTitle {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  background: linear-gradient(90deg, #b3b0ae 0%, #ffffff 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 24px;
}

.banner {
  margin-top: 16px;
  width: 100%;
  border-radius: 32px;
  overflow: hidden;
}

@media (max-width: 700px) {
  .roadmap {
    width: 112%;
    margin-left: -6%;
    padding: 24px;
    border-radius: 0px;
  }
}

.section {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 36px;
}

.title {
  font-size: 18px;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 600;
  line-height: 24px;

  background: linear-gradient(91.1deg, #b3b0ae 5.25%, #ffffff 88.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.points {
  position: relative;
  margin-top: 16px;
  width: 100%;
}

.onePoint {
  position: relative;
  width: 100%;
  height: 98px;
  display: flex;
  gap: 28px;
  justify-content: space-between;
  padding: 24px;

  border-radius: 16px;
  background: #171717;
  transition: border 0.3s;
  border: 1px solid #eda15c00;
  margin-top: 16px;
}

.onePoint:first-child {
  margin-top: 0px;
}

.onePoint:hover {
  cursor: pointer;
  border: 1px solid #eda15cdd;
}

.onePoint .right {
  width: 24px;
  height: 24px;

  margin-top: 9px;
  opacity: 0.35;
}

.onePoint .left {
  display: flex;
  gap: 16px;
}

.onePoint .left .icon {
  width: 24px;
  height: 24px;
  margin-top: 13px;
}

.onePoint .left .names {
  position: relative;
  top: 50%;
  translate: 0 -50%;
}

.onePoint .left .names .leftTitle {
  font-size: 22px;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 600;
  line-height: 28px;

  color: #ffffff;
}

.onePoint .left .names .noDesc {
  margin-top: 10px;
}

.onePoint .left .names .leftDesc {
  font-size: 14px;
  font-family: 'Bebas Neue', sans-serif;
  line-height: 20px;

  color: #818181;
}

.bottomButtons {
  position: absolute;
  right: 0px;
  bottom: 50px;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.bottomButtons .btn {
  width: auto;
  height: 48px;
  padding: 0px 32px;

  border-radius: 66px;
  background: #ffffff00;
  opacity: 1;
  transition: opacity 0.3s;
}

.bottomButtons .btn:hover {
  background: #ffffff00;
  opacity: 0.8;
}

.bottomButtons .btn p {
  color: #fff;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 24px;
  text-transform: none;
}

.bottomButtons .btn span {
  color: #fff;
}

.bottomButtons .rightBtn {
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
}

.bottomButtons .btn:hover {
  background: #ffffff00;
  opacity: 0.8;
}

.bottomButtons .rightBtn:hover {
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
}

.bottomButtons .rightBtn p {
  color: #000;
}

.threeOptions {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
}

.oneOption {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  flex-direction: column;
  width: 25%;
  border: 0.2px solid #575454;
  background-color: #171717;
  border-radius: 10px;
  cursor: pointer;
}

.selected {
  border: 1px solid #eda15c;
  background-color: #3e3d3d;
}

.leftTitle {
  color: #ffffff;
}

.leftDesc {
  color: #ff9900;
}

.customTitle {
  font-size: 16px;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 500;
  margin: 25px 0px;
  color: #ffffff;
}

@media (max-width: 920px) {
  .section {
    height: 100%;
    margin-top: 8px;
  }

  .title {
    font-size: 16px;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 500;
    line-height: 22px;

    background: #ffffff;
    background-clip: text;
  }

  .points {
    margin-top: 24px;
  }

  .onePoint {
    height: 76px;
    gap: 16px;
    padding: 16px;
    margin-top: 8px;
  }

  .onePoint:first-child {
    margin-top: 0px;
  }

  .onePoint .right {
    margin-top: 8px;
  }

  .onePoint .left .icon {
    margin-top: 10px;
  }

  .onePoint .left .names .leftTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 4px;
  }

  .onePoint .left .names .noDesc {
    margin-top: 10px;
  }

  .onePoint .left .names .leftDesc {
    font-size: 12px;
    font-family: "Roboto Condensed", sans-serif;
    line-height: 14px;

    color: #818181;
  }

  .bottomButtons {
    position: absolute;
    left: 0px;
    bottom: 140px;

    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  .bottomButtons .btn {
    width: 100%;
    flex: 100%;
  }

  .bottomButtons .rightBtn {
    margin-top: 8px;
    order: -1;
  }
}

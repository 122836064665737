.main {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 7.5% 8%;
  background-color: #0d0d0c;
  margin: 100px 0;
}

.text {
  font-family: "Roboto Condensed", sans-serif;
//   font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 140%;
  color: #ffffff;
  width: 75%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.applyButton {
  width: 20%;
  height: 56px;
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
  border-radius: 66px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  font-family: "Roboto Condensed", sans-serif;
//   font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 768px) {
  .main {
    padding: 7.5% 4%;
  }
  .text {
    font-size: 1rem;
    width: 90%;
  }
  .applyButton {
    width: 80%;
  }
}

@import "src/styles/scss/variables";

.main {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  font-family: "Roboto Condensed", sans-serif !important;
}

.section {
  width: 100%;
  height: 100%;
  margin-top: 36px;
}

.inside {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 944px;
  height: 645px;

  border-radius: 32px;
  background: #101010;

  display: flex;
  justify-content: space-between;
  padding: 40px;
}

.oneTransaction {
  width: 850px;
  height: fit-content;
  min-height: 100px;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 0.5px solid #252525;
  border-radius: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.firstRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.txChip {
  width: 100%;
  padding: 0px 0px;
  border-radius: 66px;
  background: #ffffff00;
  opacity: 1;
  transition: opacity 0.3s;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.secondRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time {
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 24px;
  color: #656565;
}

// .main {
//   position: fixed;
//   left: 0px;
//   top: 0px;
//   width: 100%;
//   height: 100vh;
//   background: rgba(0, 0, 0, 0.8);
//   z-index: 3;
// }

.title {
  font-size: 22px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 10px;
}

.txHash {
  text-decoration: underline;
  cursor: pointer;
}

.bottomButtons {
  position: absolute;
  bottom: 0;
  width: 90%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 25px;
}

.bottomButtons .btn {
  width: auto;
  height: 48px;
  padding: 0px 32px;

  border-radius: 66px;
  background: #ffffff00;
  opacity: 1;
  transition: opacity 0.3s;
}

.bottomButtons .rightBtn {
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
}

.bottomButtons .btn:hover {
  background: #ffffff00;
  opacity: 0.8;
}

.bottomButtons .rightBtn:hover {
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
}

.bottomButtons .btn p {
  color: #fff;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 24px;
  text-transform: none;
}

.bottomButtons .rightBtn p {
  color: #000;
}

.bottomButtons .btn span {
  color: #fff;
}

.transactionsList {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  padding-right: 10px;
}

@media (max-width: 920px) {
  .section {
    height: 100%;
    margin-top: 8px;
  }

  .bottomButtons {
    position: absolute;
    left: 0px;
    bottom: 140px;

    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  .bottomButtons .btn {
    width: 100%;
    flex: 100%;
  }

  .bottomButtons .rightBtn {
    margin-top: 8px;
    order: -1;
  }
}

.inside {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 944px;
  height: 645px;

  border-radius: 32px;
  background: #101010;

  display: flex;
  justify-content: space-between;
  padding: 40px;
}

.right {
  position: relative;
  width: 100%;
}

.right .top {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: flex-end;
}

.right .top .close {
  width: 24px;
  height: auto;
  color: #fff;

  transition: opacity 0.3s;
}

.right .top .close:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 1050px) {
  .inside {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 1050px) {
  .inside {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 970px) {
  .inside {
    gap: 32px;
  }

  .right {
    width: 480px;
  }
}

@media (max-width: 920px) {
  .inside {
    left: 0px;
    top: 0px;
    translate: none;
    width: 100%;
    height: 100%;
    border-radius: 0px;

    display: block;
    padding: 16px;
  }

  .right {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .right .top .close {
    position: absolute;

    right: 0px;
    top: -135px;
  }
}

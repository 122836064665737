.community {
  position: relative;
  width: 90%;

  max-width: 1136px;
  margin: 0 auto;
  margin-top: 150px;
}

.h2 {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 100%;

  text-align: center;

  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.7) 101.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;

  color: #ffffff;
  margin-top: 18px;
}

.socials {
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-top: 38px;
}

.oneSocial {
  width: auto;
  height: 56px;
  background: #161616;
  border-radius: 66px;
  padding: 0 32px;

  display: flex;
  gap: 8px;
}

.oneSocial:hover {
  background: #161616aa;
}

.oneSocial img {
  width: 24px;
  height: 24px;
}

.oneSocial p {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-transform: none;

  color: #ffffff;
}

.oneSocial span {
  color: #ffffff;
}

@media (max-width: 750px) {
  .h2 {
    font-size: 32px;
  }

  .desc {
    font-size: 14px;
  }

  .socials {
    display: block;
    margin-top: 16px;
  }

  .oneSocial {
    margin-top: 8px;
  }
}

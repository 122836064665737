.bucket {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 36px;
}

.bottomBtnDisabled {
  position: relative;
  width: 25%;
  height: 56px;

  margin-top: 16px;

  background: linear-gradient(90deg, #181716 0%, #534f4c 100%);
  border-radius: 66px;

  opacity: 1;
  transition: opacity 0.3s;
  text-transform: capitalize !important;

  color: #fff !important;

  cursor: not-allowed;
}

.timerBlock,
.tierBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  margin: -20px 0px 0px 0px;
  padding: 10px 5px;
  padding-bottom: 1.5px !important;
}

.tiers {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 6px 0px;
  border-radius: 16px;
  min-width: 300px;
  height: 70%;
  overflow-y: scroll;
}

.timerBlock {
  padding: 0px 5px;
}

.timerTitle {
  font-size: 15px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  line-height: 24px;

  color: #ffffff;
}

.title {
  font-size: 18px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  line-height: 24px;

  background: linear-gradient(91.1deg, #b3b0ae 5.25%, #ffffff 88.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.points {
  position: relative;
  margin-top: 16px;
  width: 100%;
}

.onePoint {
  position: relative;
  width: 100%;
  display: flex;
  gap: 28px;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;

  border-radius: 16px;
  background: #171717;
  transition: border 0.3s;
  border: 1px solid #eda15c00;
  margin-top: 16px;
}

.onePoint:first-child {
  margin-top: 0px;
}

.onePoint:hover {
  cursor: pointer;
  border: 1px solid #eda15cdd;
}

.selectedPoint {
  border: 1px solid #eda15c;
  background: #160e00;
}

.onePoint .left {
  display: flex;
  gap: 2px;
}

.onePoint .left .dot {
  position: relative;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background: #ffffff00;
  border: 2px solid #656565;
  border-radius: 100%;
  margin-top: 15px;
}

.selectedPoint .left .dot {
  border: 2px solid #eda15c;
}

.selectedPoint .left .dot::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  translate: -50% -50%;
  border-radius: 100%;

  background: #eda15c;
}

.onePoint .left .names .leftTitle {
  font-size: 22px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  line-height: 28px;

  color: #ffffff;
}

.onePoint .left .names .leftDesc {
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 14px;

  color: #818181;
  margin-top: 8px;
}

.onePoint .right .rightTitle {
  font-size: 22px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  line-height: 28px;

  color: #ffffff;
  text-align: right;
}

.onePoint .right .rightDesc {
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 14px;

  color: #818181;
  margin-top: 8px;
  text-align: right;
}

.bucket .disabled {
  cursor: auto;
  border: none !important;
  background: #101010;
  opacity: 0.5;
  pointer-events: none;
}

.bucket .required {
  color: red !important;
}

.bottomButtons {
  position: absolute;
  right: 0px;
  bottom: 50px;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.bottomButtons .btn {
  width: auto;
  height: 48px;
  padding: 0px 32px;

  border-radius: 66px;
  background: #ffffff00;
  opacity: 1;
  transition: opacity 0.3s;
}

.bottomButtons .rightBtn {
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
}

.bottomButtons .btn:hover {
  background: #ffffff00;
  opacity: 0.8;
}

.bottomButtons .rightBtn:hover {
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
}

.bottomButtons .btn p {
  color: #fff;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 24px;
  text-transform: none;
}

.bottomButtons .rightBtn p {
  color: #000;
}

.bottomButtons .btn span {
  color: #fff;
}

@media (max-width: 920px) {
  .bucket {
    height: 100%;
    margin-top: 8px;
  }

  .title {
    font-size: 16px;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 500;
    line-height: 22px;

    background: #ffffff;
    background-clip: text;
  }

  .points {
    margin-top: 24px;
  }

  .onePoint {
    gap: 16px;
    padding: 16px;
    margin-top: 8px;
  }

  .onePoint:first-child {
    margin-top: 0px;
  }

  .onePoint .left .names .leftTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .onePoint .left .names .leftDesc {
    margin-top: 6px;
    max-width: 140px;
  }

  .onePoint .right .rightTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .onePoint .right .rightDesc {
    margin-top: 6px;
  }

  .bottomButtons {
    position: absolute;
    left: 0px;
    bottom: 140px;

    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  .bottomButtons .btn {
    width: 100%;
    flex: 100%;
  }

  .bottomButtons .rightBtn {
    margin-top: 8px;
    order: -1;
  }
}

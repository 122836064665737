@import "src/styles/scss/variables";

.topInfo {
  position: relative;
  width: 100%;
  margin-top: 18px;

  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  gap: 16px;
}

.logo {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.leftInfo {
  margin-top: 4px;
}

.title {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;

  background: linear-gradient(90deg, #b3b0ae 0%, #ffffff 101.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.desc {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: #ffffff;
}

.rightText {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  text-align: right;

  color: #656565;
}

.socials {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.social {
  width: 32px;
  height: 32px;
  opacity: 1;
  transition: opacity 0.3s;
}

.social img {
  width: 100%;
  height: 100%;
}

.social:hover {
  opacity: 0.7;
}

@media (max-width: 800px) {
  .topInfo {
    display: block;
  }

  .right {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .socials {
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .logo {
    width: 56px;
    height: 56px;
  }

  .title {
    font-size: 24px;
  }

  .desc {
    font-size: 14px;
  }
}

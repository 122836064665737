@import "../../../../styles/scss/variables.scss";

.footer {
  position: relative;
  width: 90%;

  max-width: 1136px;
  margin: 0 auto;
  margin-top: 150px;

  background: #101010;
  border-radius: 32px;

  padding: 40px 96px;
  margin-bottom: 64px;

  display: flex;
  justify-content: space-between;
}

.logo {
  width: 170px;
  height: auto;
}

.socials {
  display: flex;
  gap: 22px;
  margin-top: 12px;
}

.oneSocial {
  width: 48px;
  height: 48px;
  min-width: 0px;
  background: #ffffff00;
  border-radius: 100%;
  opacity: 0.8;
  transition: opacity 0.3s;
  margin-left: -6px;
}

.oneSocial:hover {
  background: #ffffff00;
  opacity: 1;
}

.oneSocial img {
  width: 100%;
  height: 100%;
}

.oneSocial span {
  color: #ffffff;
}

.bottomLinks {
  display: flex;
  gap: 32px;
  margin-top: 102px;
}

.bottomLinksMobile {
  display: none;
}

.bottomLinks a {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #bababa;
  transition: color 0.3s;
}

.bottomLinks a:hover {
  color: #ffffff;
}

.topTables {
  display: flex;
  justify-content: flex-end;
  gap: 88px;
}

.tableTitle {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;

  color: #ffffff;
}

.oneTable a {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #bababa;
  transition: color 0.3s;
  margin-top: 18px;

  display: block;
}

.oneTable a:hover {
  color: #ffffff;
}

.copyright {
  position: absolute;
  right: 96px;
  bottom: 40px;

  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #bababa;
  //margin-top: 100px;
  text-align: right;
}

@media (max-width: 900px) {
  .footer {
    margin-top: 80px;
    border-radius: 24px;

    padding: 30px 32px;
    margin-bottom: 32px;

    display: block;
  }

  .left {
    position: relative;
    width: 100%;
  }

  .right {
    position: relative;
    width: 100%;
    margin-top: 24px;
  }

  .topTables {
    justify-content: space-between;
  }

  .bottomLinks {
    width: 100%;
    margin-top: 32px;
  }

  .copyright {
    position: relative;
    width: 100%;
    right: 0px;
    bottom: 0px;
    text-align: left;
    margin-top: 12px;
  }
}

@media (max-width: 450px) {
  .bottomLinks {
    display: none;
  }

  .bottomLinksMobile {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
  }

  .bottomLinksMobile a {
    position: relative;
    width: 100%;
    flex: 100%;
  }

  .topTables {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 32px;
  }

  .oneTable {
    width: 100%;
    flex: 100%;
  }
}

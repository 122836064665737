@import "src/styles/scss/variables";

.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 8% 20%;
  max-width: 1280px;
}

.top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}

.topTitle {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 65px;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.7) 101.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 20px 0;
}

.topDesc {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: #ffffff;
  margin-top: 8px;
}

.topRight {
  width: auto;
  min-width: 220px;
  height: 48px;
  background: #161616;
  border-radius: 66px;

  padding: 0 32px;
  transition: background 0.3s;
  margin-top: 6px;

  display: flex;
  gap: 8px;
}

.topRight:hover {
  background: #161616dd;
}

.topRight p {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-transform: none;

  color: #ffffff;
}

.topRight span {
  color: #ffffff;
}

.bottom {
  position: relative;
  width: 100%;
  display: flex;
  gap: 24px;
  margin-top: 33px;
}

// @media (max-width: 1250px) {
//   .bottom {
//     flex-wrap: wrap;
//     justify-content: center;
//     gap: 24px;
//   }
// }

@media (max-width: 1100px) {
  .topLeft {
    display: none;
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: fit-content !important;
    margin: 8% 1%;
  }

  .topTitle {
    text-align: center;
  }

  .bottom {
    order: 1;
    flex: 100%;
    margin-top: 0px;
  }

  .top {
    // order: 2;
    // flex: 100%;
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content !important;
    overflow-x: scroll;
    width: 100%;
  }
}

// @media (max-width: 430px) {
//   .topRight {
//     width: 100%;
//   }
// }

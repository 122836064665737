@import 'src/styles/scss/variables';

.tabs {
  position: relative;
  width: 100%;
  margin-top: 24px;
  margin-left: 24px;

  display: flex;
  gap: 75px;
}

.oneTab {
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #656565;
  transition: color 0.3s;
}

.oneTab:hover {
  color: #dddddd;
  cursor: pointer;
}

.selectedTab {
  position: relative;
  color: #ffffff;
}

.selectedTab::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -24px;
  translate: -50% 0;
  width: 60%;
  height: 2px;
  border-radius: 100%;
  background: #ffffff;
}

@media (max-width: 1150px) {
  .tabs {
    width: calc(100% - 24px - 24px);
    gap: 0px;
    justify-content: space-between;
  }
}

@media (max-width: 550px) {
  .tabs {
    width: calc(100%);
    margin-left: 0px;
  }

  .oneTab {
    font-size: 14px;
  }
}

@import "../../../../styles/scss/variables.scss";

.notis {
  position: relative;
  width: 100%;
}

.top {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.top .left {
  position: relative;

  color: #fff;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.top .right {
  position: relative;

  color: #656565;
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  transition: opacity 0.3s;
}

.top .right:hover {
  cursor: pointer;
  opacity: 0.8;
}

.elements {
  position: relative;
  width: 100%;
  margin-top: 8px;
}

.oneNoti {
  position: relative;
  width: 100%;

  border-top: 1px solid #2f2f2f;

  padding: 16px 8px;

  display: flex;
  gap: 4px;

  background: #2f2f2f00;
  transition: background 0.3s;
}

.oneNoti:hover {
  cursor: pointer;
  background: #2f2f2f;
}

.oneNoti .icon {
  position: relative;
  width: 20px;
  height: 20px;
}

.oneNoti .icon img {
  width: 100%;
  height: 100%;
}

.oneNoti .texts {
  position: relative;
}

.oneNoti .texts .top {
  position: relative;

  color: #fff;
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.oneNoti .texts .bottom {
  position: relative;
  margin-top: 4px;

  color: #656565;
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

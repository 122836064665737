@import "styles/scss/variables";

.gradientText {
  background: linear-gradient(to right, #b3b0ae, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.salesCont {
  display: flex;

  gap: 32px;
}

.salesCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  @media (max-width: 992px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 calc(-50vw + 50%);
    padding: 0 calc(50vw - 50%);
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
.salesCards::-webkit-scrollbar {
  display: none;
}
.singleCard {
  @media (max-width: $screen-sm) {
    justify-content: center;
  }
}

.launchPadProjects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  margin: 8% 0%;
  max-width: 1280px;
  width: 100%;
}

.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 8% 20%;
  max-width: 1280px;
}

.top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}

.topTitle {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 65px;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.7) 101.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 20px 0;
}

.mainPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 64px;
  padding-top: 80px !important;
  padding-bottom: 0 !important;

  //only to adjust the page to existing rest part of app's code
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: 1;
  //

  @media (max-width: 450px) {
    padding-top: unset !important;
  }
}

@media (max-width: 1100px) {
  .topLeft {
    display: none;
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: fit-content !important;
    margin: 8% 1%;
  }

  .topTitle {
    text-align: center;
  }

  .bottom {
    order: 1;
    flex: 100%;
    margin-top: 0px;
  }

  .top {
    // order: 2;
    // flex: 100%;
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content !important;
    // overflow-y: scroll;
    width: 100%;
  }
}

.gsBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 20%;
}

.gsTitle {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 65px;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.7) 101.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 20px 0;
}

.cardsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2.5%;
  padding: 0 4%;
}

.card {
  width: 48%;
  height: auto;
  background: #101010;
  border-radius: 24px;
  overflow: hidden;
  transition: opacity 0.3s;
  padding: 20px;
  min-height: 400px;
}

.cardTitle {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 20px;
}

.card > ul {
  font-size: 1rem;
  line-height: 24px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  padding-left: 10px;
}

.card > ul > li {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .gsBlock {
    margin: 5% 2%;
  }
  .gsTitle {
    font-size: 30px;
  }

  .cardTitle {
    font-size: 20px;
  }

  .cardsRow {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }

  .card > ul {
    font-size: 0.8rem;
  }
}

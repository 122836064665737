@import "styles/scss/variables";

.tiertimer-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 6px 24px;
  border-radius: 16px;
  background-color: black;
  min-width: 300px;
  @media (max-width: 450px) {
    padding: 12px;
  }
}

.tiertimer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tier-remaining-time {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.tier-time-units {
  font-family: $header-font-family !important;
}
.tier-time-values {
  font-family: $header-font-family !important;
  font-weight: $text-weights-normal;
  font-size: 24px;
  @media (max-width: 450px) {
    font-weight: $text-weights-semi-bold;
  }
}

@import 'src/styles/scss/variables';

.banner {
  position: relative;
  width: 100%;

  background: #171717;
  border-radius: 32px;
  padding: 24px;
}

.banner img {
  width: 100%;
  height: 100%;

  background: linear-gradient(0deg, #090702, #090702);
  border: 1px solid rgba(129, 129, 129, 0.2);
  border-radius: 24px;
}

@media (max-width: 700px) {
  .banner {
    padding: 11px;
    border-radius: 16px;
  }

  .banner img {
    border-radius: 10px;
  }
}

@import './src/styles/scss/variables';

.label {
  background-color: $color--background-primary;
  border: 1px solid $color--primary;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
  padding: 0 4px;
  width: 50px;
  white-space: nowrap;

  &-ready {
    width: auto;
  }
}

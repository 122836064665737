@import "src/styles/scss/variables";

.launch {
  position: relative;
  width: 90%;

  max-width: 1136px;
  margin: 0 auto;
  margin-top: 120px;
  z-index: 3;
}

.h2 {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;

  text-align: center;

  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.7) 101.85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.card {
  width: 100%;
  max-width: 1136px;

  margin: 0 auto;
  margin-top: 46px;
  padding: 43px 48px;

  background: #171717;
  border-radius: 32px;

  gap: 16px;
  display: flex;
  justify-content: space-between;
}

.left {
  margin-top: 21px;
}

.left .leftLogo {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}

.left .title {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;
  margin-top: 24px;

  background: linear-gradient(
    180deg,
    #ffffff 22.5%,
    rgba(255, 255, 255, 0.7) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.left .desc {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  max-width: 400px;

  color: #ffffff;
  margin-top: 16px;
}

.left .infos {
  width: 100%;
  max-width: 400px;
  margin-top: 8px;
}

.oneInfo {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.leftInfo {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #656565;
}

.rightInfo {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: #ffffff;
}

.left .buttons {
  margin-top: 32px;

  display: flex;
  gap: 16px;
}

.btn {
  width: auto !important;
  height: 48px !important;
  border-radius: 66px !important;

  padding: 0 32px !important;
  transition: opacity 0.3s;
}

.btn:hover {
  opacity: 0.8;
}

.btn p {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-transform: none;

  color: #ffffff;
}

.btn span {
  color: #ffffff;
}

.leftBtn {
  background: linear-gradient(90deg, #eda15c 0%, #a85b08 100%);
}

.leftBtn p {
  color: #000000;
}

.rightBtn {
  background: none;
}

.rightBtn:hover {
  background: none;
}

.right {
  width: 520px;
}

.rightBanner {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 22px;
  overflow: hidden;
}

.rightBanner img {
  position: absolute;
  top: 0px;
  left: 50%;
  translate: -50% 0;
  width: auto;
  height: 100%;
}

.mobileBanner {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 22px;

  display: none;
  overflow: hidden;
}

.mobileBanner img {
  position: absolute;
  top: 0px;
  left: 50%;
  translate: -50% 0;
  width: auto;
  height: 100%;
  z-index: -1;
}

.bottom {
  margin-top: 15px;

  display: flex;
  gap: 16px;
}

.bottomInfo {
  background: #080601;
  border-radius: 24px;
  padding: 24px;
  width: 164px;
  min-height: 128px;
}

.timer {
  width: calc(100% - 164px - 16px);
  height: 128px;
  background: #080601;
  border-radius: 24px;
}

.bottomInfoRight {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.bottomInfo .raised {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;

  color: #656565;
}

.bottomInfo .timeAmount {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 127%;

  color: #ffffff;
  margin-top: 6px;
}

.bottomInfo .amount {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 127%;

  color: #eda15c;
  margin-top: 6px;
}

.bottomInfo .coin {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;

  color: #ffffff;
}

@media (max-width: 1200px) {
  .card {
    position: relative;
    max-width: 700px;
    border-radius: 0px;
    z-index: 1;

    flex-wrap: wrap;
  }

  .card::before {
    position: absolute;
    content: "";
    background: #171717;
    width: 250%;
    height: 100%;

    top: 0px;
    left: -75%;
    z-index: -1;
  }

  .left {
    flex: 100%;
    margin-top: 8px;
  }

  .right {
    order: 1;
    flex: 100%;
    margin-top: 24px;
  }

  .left .title {
    text-align: center;
  }

  .left .desc {
    text-align: center;
    margin: 0 auto;
    margin-top: 16px;
  }

  .left .buttons {
    justify-content: center;
  }

  .left .infos {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 12px;
  }

  .left .leftLogo {
    width: 48px;
    height: 48px;

    margin-left: 25px;
    margin-top: -100px;
  }

  .rightBanner {
    display: none;
  }

  .mobileBanner {
    display: block;
  }
}

@media (max-width: 750px) {
  .h2 {
    font-size: 32px;
  }

  .card {
    margin-top: 27px;
    padding: 24px 16px;
  }
}

@media (max-width: 550px) {
  .launch {
    width: 100%;
  }

  .left .title {
    font-size: 24px;
    line-height: 140%;
  }

  .left .desc {
    font-size: 14px;
    line-height: 140%;
  }

  .left .buttons {
    margin-top: 16px;
    display: block;
  }

  .btn {
    width: 100% !important;
  }

  .rightBtn {
    margin-top: 8px;
  }

  .bottom {
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 8px;
  }

  .bottomInfo {
    width: 100%;
  }

  .timer {
    width: 100%;
    padding: 6px;
  }

  .bottomInfoRight {
    justify-content: space-between;
  }
}

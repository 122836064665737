@import 'src/styles/scss/variables';

.main {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 3;
}

.inside {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 944px;
  height: 645px;

  border-radius: 32px;
  background: #101010;

  display: flex;
  justify-content: space-between;
  padding: 40px;
}

.right {
  position: relative;
  width: 520px;
}

.right .top {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: flex-end;
}

.right .top .close {
  width: 24px;
  height: auto;
  color: #fff;

  transition: opacity 0.3s;
}

.right .top .close:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 1050px) {
  .inside {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 1050px) {
  .inside {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 970px) {
  .inside {
    gap: 32px;
  }

  .right {
    width: 480px;
  }
}

@media (max-width: 920px) {
  .inside {
    left: 0px;
    top: 0px;
    translate: none;
    width: 100%;
    height: 100%;
    border-radius: 0px;

    display: block;
    padding: 16px;
  }

  .right {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .right .top .close {
    position: absolute;

    right: 0px;
    top: -135px;
  }
}
